<div class="container address-container">
    <div class="row">
        <div class="col-xs-1">
            <span class="address-icon"><i class="fa fa-map-marker"></i></span>
        </div>
        <div class="col-xs-11">
            <span>Calle Puno 154 Santa Patricia La Molina
                <br>
                Lima - Perú</span>
        </div>
    </div>
</div>