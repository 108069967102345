import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-logitech',
  templateUrl: './logitech.component.html',
  styleUrls: ['./logitech.component.less']
})
export class LogitechComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
