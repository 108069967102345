<form class="container" [formGroup]="contactForm" (ngSubmit)="onSubmit()" *ngIf="contactForm">
    <h3>Contáctanos</h3>
    <div class="row">
        <div class="form-group col-lg-12 col-xs-12">
            <input type="text" class="form-control" formControlName="name" name="name" placeholder="Nombre" [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
            <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                <div *ngIf="f.name.errors.required">Nombre es requerido</div>
            </div>
        </div>
        <div class="form-group col-lg-12 col-xs-12">
            <input type="text" class="form-control" formControlName="phone" name="phone" placeholder="Teléfono" [ngClass]="{ 'is-invalid': submitted && f.phone.errors }">
            <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                <div *ngIf="f.company.errors.required">Teléfono es requerido</div>
            </div>
        </div>
        <div class="form-group  col-lg-12 col-xs-12">
            <input type="text" class="form-control" formControlName="company" name="company" placeholder="Empresa" [ngClass]="{ 'is-invalid': submitted && f.company.errors }">
            <div *ngIf="submitted && f.company.errors" class="invalid-feedback">
                <div *ngIf="f.company.errors.required">Empresa es requerida</div>
            </div>
        </div>
        <div class="form-group  col-lg-12 col-xs-12">
            <input type="email" class="form-control" formControlName="email" name="email" aria-describedby="email" placeholder="Email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                <div *ngIf="f.email.errors.required">Email es requerido</div>
                <div *ngIf="f.email.errors.email">Email debe ser válido</div>
            </div>
        </div>
        <div class="form-group col-lg-12 col-xs-12">
            <textarea type="text" rows="4" class="form-control" formControlName="body" name="body" placeholder="Agregar respuesta aquí" [ngClass]="{ 'is-invalid': submitted && f.body.errors }"></textarea>
            <div *ngIf="submitted && f.body.errors" class="invalid-feedback">
                <div *ngIf="f.body.errors.required">La respuesta es requerida</div>
            </div>
        </div>
        <div id="btn-wrapper" class="text-center col-lg-12 col-xs-12">
            <button type="submit" class="btn btn-primary bold uppercase">Enviar Mensaje</button>
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col-lg-7 col-md-12">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3901.6023713970994!2d-76.94355414261624!3d-12.07085647393085!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105c6e7d1ce5d21%3A0x8ffb380d922b3b58!2sPuno%20154%2C%20Cercado%20de%20Lima%2015024!5e0!3m2!1ses-419!2spe!4v1599187576891!5m2!1ses-419!2spe"
                width="600" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
        </div>
        <div class="col-lg-4 offset-lg-1 col-md-12">
            <div class="row">
                <div class="col-xs-1">
                    <span class="address-icon"><i class="fa fa-map-marker"></i></span>
                </div>
                <div class="col-xs-11">
                    <span>Calle Puno 154 Santa Patricia La Molina
                        <br>
                        Lima - Perú</span>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-1">
                    <span class="address-icon"><i class="fa fa-envelope"></i></span>
                </div>
                <div class="col-xs-11">
                    <span class="mail">
                        <a href="mailto:contacto@colaboratics.pe">contacto@tics.pe</a>
                    </span>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-1">
                    <span class="address-icon"><i class="fa fa-phone"></i></span>
                </div>
                <div class="col-xs-11">
                    <span class="phone"> 51.995042871</span>
                </div>
            </div>
        </div>
    </div>

</form>