import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-footer-address',
  templateUrl: './footer-address.component.html',
  styleUrls: ['./footer-address.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class FooterAddressComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
