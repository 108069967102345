import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer-contact',
  templateUrl: './footer-contact.component.html',
  styleUrls: ['./footer-contact.component.less']
})
export class FooterContactComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
