<div class="container phone-container">
    <div class="row">
        <div class="col-xs-1">
            <span class="address-icon"><i class="fa fa-envelope"></i></span>
        </div>
        <div class="col-xs-11">
            <span class="mail">
                <br>
                <a href="mailto:contacto@tics.pe">contacto@tics.pe</a>
            </span>
        </div>
    </div>
</div>