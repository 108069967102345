<div class="container-fluid footer mt-auto py-3">
    <div class="row">
        <div class="col-lg-4 col-sm-12">
            <app-footer-address></app-footer-address>
        </div>
        <div class="col-lg-4 col-sm-12 mr-auto">
            <app-social-media></app-social-media>
        </div>
        <div class="col-lg-3 offset-lg-1  col-sm-12">
            <app-footer-contact></app-footer-contact>
        </div>
    </div>
</div>