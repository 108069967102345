<div class="container-fluid header">
    <nav class="navbar navbar-expand-lg navbar-light bg-light rounded">
        <a class="navbar-brand" href="#">
            <img class="colaboratics-logo pull-left" src="/assets/images/colaboratics.png" alt="colaboratics">
        </a>
        <button dropdownToggle class="navbar-toggler collapsed d-md-block d-lg-none" type="button" (click)="isCollapsed = !isCollapsed" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

        <div class="collapse navbar-collapse center" id="navbarSupportedContent" [collapse]="isCollapsed">
            <ul class="navbar-nav mr-auto">
                <li class="nav-item" routerLinkActive="active">
                    <a class="nav-link" [routerLink]="['home']">INICIO <span class="sr-only"></span></a>
                </li>
                <li class="nav-item" *ngIf="false" routerLinkActive="active">
                    <a class="nav-link" [routerLink]="['']">SOLUCIONES<span class="sr-only"></span></a>
                </li>
                <li class="nav-item" routerLinkActive="active">
                    <a class="nav-link" [routerLink]="['logitech']">LOGITECH<span class="sr-only"></span></a>
                </li>
                <li class="nav-item" *ngIf="false" routerLinkActive="active">
                    <a class="nav-link" [routerLink]="['store']">TIENDA VIRTUAL<span class="sr-only"></span></a>
                </li>
                <li class="nav-item" routerLinkActive="active">
                    <a class="nav-link" [routerLink]="['contact']">CONTACTO<span class="sr-only"></span></a>
                </li>
            </ul>
        </div>
    </nav>
</div>