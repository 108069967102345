<section class="section">
    <carousel (activeSlideChange)="onActiveSlideChange($event)">
        <slide *ngFor="let slide of slides; let index=index">
            <div [@openClose]="slide.open ? 'open' : 'closed'" class="carousel-txt">
                <span *ngFor="let text of slide.texts" [ngClass]="text.colorCls">{{text.value}}<br></span>
            </div>
            <img [src]="slide.image" class="banner-img">
        </slide>
    </carousel>

</section>
<section class="section">
    <div class="container">
        <hr>
        <div class="row">
            <div class="col-md-12">
                <p>Somos una empresa integradora de tecnologías y soluciones con un equipo de profesionales experimentados. Queremos ser el aliado tecnológico para atender las diversas necesidades de desarrollo, implementación de recursos informáticos, herramientas
                    colaborativas y seguridad informática, en tu empresa o institución educativa.
                </p>
                <p>Con nuestras soluciones los guiaremos y ayudaremos a impulsar su transformación digital y gestión de procesos de TI. También al mejor uso de la misma para impulsar la colaboración y mejorar la comunicación entre sus usuarios.
                </p>
            </div>
        </div>

        <div class="row">
            <div class="col-md-4">
                <i class="fa fa-home" aria-hidden="true"></i> <span class="bold uppercase header">¿Por qué lo hacemos?</span>
                <p>Creemos en el uso de la tecnología como medio para mejorar la calidad de vida y ser una mejor sociedad.</p>
            </div>
            <div class="col-md-4">

                <i class="fa fa-map-marker" aria-hidden="true"></i> <span class="bold uppercase header">¿Cómo lo hacemos?</span>
                <p>Mejorando la experiencia de adopción e implementación con tecnologías intuitivas y cotidianas.</p>
            </div>
            <div class="col-md-4">

                <i class="fa fa-tv" aria-hidden="true"></i> <span class="bold uppercase header">¿Qué hacemos?</span>
                <p>Integrando las mejores tecnologías de hardware, software y servicios especializados para transformar digitalmente tu empresa.</p>
            </div>
        </div>
    </div>
</section>
<section class="section">
    <div class="parallax-container">
        <div class="parallax" appParallax [ratio]="-0.3"><img src="assets/images/bannertrabajadesdecasa.png" title="Soluciones para crecer" style="display: block; transform: translate3d(-50%, 72px, 0px);"></div>
    </div>
    <div class="section-image">
        <div class="section-cover"></div>
        <div class="section-text apear-js align_left container">
            <div id="txt-wfh-box"></div>
            <span id="txt-wfh-1">TRABAJA DESDE CASA</span>
            <span id="txt-wfh-2">Soluciones que aumentan tu productividad</span>
        </div>
    </div>
</section>
<section class="section">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-xs-12" *ngFor="let preview of previews">
                <app-preview [preview]="preview"></app-preview>
            </div>
        </div>
    </div>

</section>